<template>
    <div class="powersol-form-panel mc-form-panel">
        <div v-if="isSubmitted">
            <div class="row">
                <div class="w-full text-left p-6">

                    <h3 class="mb-4 text-red font-avenir font-extrabold uppercase text-2xl">{{ $t('missionCriticalForm.submitted' ) }}</h3>

                    <p class="mb-4">{{ $t('missionCriticalForm.sentInfo' ) }}</p>

                    <p class="text-lg">
                        <a href="#" class="btn-text" @click.prevent="reload()">{{ $t('missionCriticalForm.return' ) }}</a>
                    </p>

                </div>
            </div>
        </div>

        <div v-else>

            <form @submit.prevent="submit" method="post" novalidate>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <p>* Required Field</p>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="firstName">{{ $t('missionCriticalForm.firstName') }}*</label>
                        <input type="text" class="form-control" id="firstName" v-model.lazy.trim="form.firstName" @blur="onFieldBlur('firstName')" v-bind:class="getFieldClasses('firstName')">
                        <div v-if="isErrorField('firstName')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('missionCriticalForm.firstName') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="lastName">{{ $t('missionCriticalForm.lastName') }}*</label>
                        <input type="text" class="form-control" id="lastName" v-model.lazy.trim="form.lastName" @blur="onFieldBlur('lastName')" v-bind:class="getFieldClasses('lastName')">
                        <div v-if="isErrorField('lastName')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('missionCriticalForm.lastName') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="companyName">{{ $t('missionCriticalForm.companyName') }}*</label>
                        <input type="text" class="form-control" id="companyName" v-model.lazy.trim="form.companyName" @blur="onFieldBlur('companyName')" v-bind:class="getFieldClasses('companyName')">
                        <div v-if="isErrorField('companyName')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('missionCriticalForm.companyName') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="roleTitle">{{ $t('missionCriticalForm.roleTitle') }}*</label>
                        <input type="text" class="form-control" id="roleTitle" v-model.lazy.trim="form.roleTitle" @blur="onFieldBlur('roleTitle')" v-bind:class="getFieldClasses('roleTitle')">
                        <div v-if="isErrorField('roleTitle')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('missionCriticalForm.roleTitle') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="email">{{ $t('missionCriticalForm.email') }}*</label>
                        <input type="email" class="form-control" id="email" v-model.lazy.trim="form.email" @blur="onFieldBlur('email')" v-bind:class="getFieldClasses('email')">
                        <div v-if="isErrorField('email')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('missionCriticalForm.email') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="phone">{{ $t('missionCriticalForm.phone') }}</label>
                        <input type="text" class="form-control" id="phone" v-model.lazy.trim="form.phone" @blur="onFieldBlur('phone')" v-bind:class="getFieldClasses('phone')">
                        <div v-if="isErrorField('phone')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('missionCriticalForm.phone') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="typeOfInquiryPS">{{ $t('missionCriticalForm.typeOfInquiryPS') }}*</label>
                        <select class="form-control" v-model.lazy.trim="form.typeOfInquiryPS">
                            <option value="Customer">Customer</option>
                            <option value="Supplier">Supplier</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="message">{{ $t('missionCriticalForm.message') }}*</label>
                        <textarea
                                type="message"
                                class="form-control"
                                id="message"
                                v-model.trim="form.message"
                                v-bind:class="getFieldClasses('message')"
                                @blur="onFieldBlur('message')">
                        </textarea>
                        <div v-if="isErrorField('message')" class="error-messages">{{  $t('error.fieldInvalid', { field: $t('missionCriticalForm.message') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full text-lg">
                        <button class="btn-arrow" :disabled="submitting">
                            Submit
                            <svg class="right-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,0l0,78.76l20.01,-39.357l-20.01,-39.403Z" ></path></svg>
                        </button>
                        <div v-if="submitting" class="px-1 pt-2"><img src="/assets/images/ajax-loader2.gif" class="inline"><br>Submitting form, please wait.</div>
                        <p v-if="isError" class="pt-3 px-1 text-red-light font-bold">Your submission has errors in it. Please review the form and try again.</p>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import axios from 'axios';
    //import {mask} from 'vue-the-mask';
    //import { InputFacade, facade, filter } from 'vue-input-facade';
    /*
        const emailList = {
            'a': ['info@mmrps.com'],
            'b': ['privacyquestions@mmrgrp.com']
        }
    */
    export default {
        name: 'mission-critical-form',
        data() {
            return {
                isSubmitted: false,
                isError: false,
                errorHeader: 'error.invalidFields',
                errors: [],
                submitting: false,
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    companyName: '',
                    roleTitle: '',
                    phone: '',
                    typeOfInquiryPS: 'Customer',
                    //'departmentRecipient[]': '',
                    //department: 'a',
                    message: '',
                    formHash: '',
                    hptName: '',
                    hptHash: '',
                }
            }
        },
        //directives: { mask },
        /*
        components: { InputFacade },
        directives: { facade },
        filters: { facade: filter },
         */
        created: function () {
            // `this` points to the vm instance
            const self = this;

            function getFormHash() {
                return axios.get('/forms/formhashgetter/missionCriticalForm');
            }

            function getHptName() {
                return axios.get('/forms/hptnamegetter/missionCriticalForm');
            }

            function getHptHash() {
                return axios.get('/forms/hpthashgetter/missionCriticalForm');
            }

            axios.all([getFormHash(), getHptName(), getHptHash()])
                .then(axios.spread(function (fHash, hName, hHash) {

                    // Both requests are now complete
                    self.form.formHash = fHash.data.trim();
                    self.form.hptName = hName.data.trim();
                    self.form.hptHash = hHash.data.trim();
                    console.log(self.form.formHash);
                    console.log(self.form.hptName);
                    console.log(self.form.hptHash);
                }));
        },
        methods: {
            /*
            setRecip(event) {
                const sel = event.target;
                console.log(sel.options[sel.selectedIndex].text);
                console.log(sel.options[sel.selectedIndex].text == 'Privacy Request - Erase My Personal Info');
                if (sel.options[sel.selectedIndex].text == 'Privacy Request - Erase My Personal Info' || sel.options[sel.selectedIndex].text == 'Privacy Request - Export My Personal Info') {
                    this.form.department = 'b';
                } else {
                    this.form.department = 'a';
                }
            },

             */
            submit() {
                this.$v.$touch();
                if (!this.$v.$error) {
                    this.sendFormData();
                } else {
                    this.validationError();
                }
            },
            enableSubmitLoader() {
                this.submitting = true;
            },
            disableSubmitLoader() {
                this.submitting = false;
            },
            sendFormData() {
                this.enableSubmitLoader();
                let formData = new FormData();
                let formInput = this.form;
                formData.append('action', 'freeform/api/form');
                formData.append(this.$parent.csrfName, this.$parent.csrfTokenValue);
                formData.append('formHash', this.form.formHash);
                //formData.append(this.form.hptName, this.form.hptHash);
                //this.form['departmentRecipient[]'] = emailList[this.form.department];
                Object.keys(formInput).forEach(function (item) {
                    formData.append(item, formInput[item]);
                });

                //let formData = JSON.stringify(this.form);
                axios.post('/', formData, {
                    headers: {
                        'X-CSRF-Token': this.$parent.csrfTokenValue,
                        "X-Requested-With": "XMLHttpRequest",
                        "HTTP_X_REQUESTED_WITH": "XMLHttpRequest",
                    }
                }).then(response => {
                    this.submitSuccess(response);
                    this.disableSubmitLoader();
                }).catch(error => {
                    this.submitError(error);
                    this.disableSubmitLoader();
                });

            },
            submitSuccess(response) {
                if (response.data.success) {
                    this.isSubmitted = true;
                    this.isError = false;
                } else {
                    this.errorHeader = 'error.invalidFields';
                    this.errors = response.data.errors;
                    this.isError = true;
                }
            },
            submitError(error) {
                console.log('submitError');
                console.log(error.message);
                this.errorHeader = 'error.general';
                this.errors = [{'field': null, 'message': 'error.generalMessage'}];
                this.isError = true;
            },
            validationError() {
                console.log('validationError');
                this.errorHeader = 'error.invalidFields';
                this.errors = this.getErrors();
                this.isError = true;
            },
            isErrorField(field) {
                try {
                    if (this.getValidationField(field).$error) {
                        return true;
                    }
                } catch (error) {}

                return this.errors.some(el => el.field === field);
            },
            getErrors() {
                let errors = [];
                for (const field of Object.keys(this.form)) {
                    try {
                        if (this.getValidationField(field).$error) {
                            errors.push({'field': field, 'message': null});
                        }
                    } catch (error) {}
                }
                return errors;
            },
            getFieldClasses(field) {
                return { 'is-invalid': this.isErrorField(field) }
            },
            getValidationField(field) {
                if (this.$v.form[field]) {
                    return this.$v.form[field];
                }
                throw Error('No validation for field ' + field);
            },
            onFieldBlur(field) {
                try {
                    this.getValidationField(field).$touch();
                    if (this.getValidationField(field).$error) {
                        if (!this.errors.some(el => el.field === field)) {
                            this.errors.push({'field': field, 'message': null});
                        }
                    } else {
                        this.errors = this.errors.filter(el => el.field !== field);
                    }
                } catch (error) {}
            },
            reload() {
                window.location = '/services/mission-critical-construction';
            }
        },
        validations() {
            return {
                form: {
                    email: { required, email },
                    firstName: { required },
                    lastName: { required },
                    companyName: { required },
                    roleTitle: { required },
                    typeOfInquiryPS: { required },
                    message: { required }
                }
            }
        },
        watch: {
            errors() {
                this.isError = this.errors.length > 0 ? true : false;
            }
        }
    }
</script>
