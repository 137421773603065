<template>
    <div class="timeline-content">
        <div class="timeline-wrapper">
            <VOwlCarousel :responsive="{0:{items:1},640:{items:2},768:{items:3},1024:{items:5}}" :autoplay="false" :loop="false" :dots="false" :nav="false">
                <template slot="prev">
                    <button class="owl-prev">
                    </button>
                </template>

                <template slot="next">
                    <button class="owl-next">
                    </button>
                </template>

                <template slot="default">
                    <slot></slot>
                </template>
            </VOwlCarousel>
        </div>
    </div>
</template>

<script>
    import VOwlCarousel from "v-owl-carousel";
    export default {
        name: "timeline",
        data() {
            return {

            }
        },
        created: function () {
            // `this` points to the vm instance

        },
        components: {
            VOwlCarousel
        },
        methods: {

        }
    }
</script>

