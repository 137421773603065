import Vue from "vue";
import Components from "./components";
import "./app.css";
import Vue2TouchEvents from 'vue2-touch-events';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import translations from './resources/translations';
import axios from 'axios';
import VScrollLock from 'v-scroll-lock'
import 'lazysizes';

Vue.config.productionTip = false;

const main = async () => {

    // Async load the vue module

    Vue.use(VScrollLock);
    Vue.use(Vue2TouchEvents);
    Vue.use(VueI18n);
    Vue.use(Vuelidate);

    const i18n = new VueI18n({

        locale: 'en',
        fallbackLocale: 'en',
        messages: translations
    });

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        i18n,
        delimiters: ['[[', ']]'],
        data: {
            menuActive: false,
            servicePanelActive: false,
            modalActive: false,
            modalContent: '',
            tlModal: false,
            csrfTokenValue: '',
            csrfName: '',
            newsActive: '',
        },
        methods: {
            closeModal: function() {
                this.modalActive = false;
            },
             afterEnter: function() {
                const modalEl = document.getElementById('modal-overlay');
                modalEl.classList.add('modal-visible');
                if (document.getElementById('small-map-container') != null) {
                    new Vue({
                        el: '#small-map-container'
                    });
                }
            },
            afterLeave: function() {
                this.modalContent = '';
            },
            goToAction: function() {
                const wHeight = window.innerHeight;
                const scrollDist = window.pageYOffset;
                const gotoBtn = document.getElementById('go-to-top');
                if (scrollDist > wHeight) {
                    gotoBtn.classList.add('scrolled');
                } else {
                    gotoBtn.classList.remove('scrolled');
                }
            }
        },
        mounted() {
            const self = this;

            function getCsrf() {
                return axios.get('/actions/blitz/csrf/token');
            }

            function getCsrfName() {
                return axios.get('/actions/blitz/csrf/param');
            }

            axios.all([getCsrf(), getCsrfName()])
                .then(axios.spread(function (csrf, csrfName) {

                    // Both requests are now complete
                    self.csrfTokenValue = csrf.data.trim();
                    self.csrfName = csrfName.data.trim();
                }));

            document.addEventListener('click', function (event) {

                // If the clicked element doesn't have the right selector, bail
                if (event.target.id != 'canada-link') return;

                // Don't follow the link
                event.preventDefault();

                // Log the clicked element in the console
                const formFrame = document.getElementById('iframe-form');
                const canadaFormUrl = 'https://careers.mmrgrp.com/Careers/FieldCraftCanada';
                formFrame.src = canadaFormUrl;
                formFrame.classList.add('canada-form');
                document.getElementById('canada-msg').style.display = 'none';
            }, false);

        },
        created() {
            window.addEventListener('scroll', this.goToAction);
            /*
            function iframeSizer(iFrame) {
                iFrame.height = (iFrame.contentWindow.document.body.scrollHeight + 50) + 'px';
            }
            function resizeIframeForms() {
                const formFrame = document.getElementById('iframe-form');
                iframeSizer(formFrame);
            }

            window.addEventListener('DOMContentLoaded', function(e) {
               resizeIframeForms();
            });
            window.addEventListener('resize', function(e) {
                resizeIframeForms();
            });

             */
        }
    });

    return vm;
};

// Execute async function

const initMain = () => {
    // Execute async function
    main().then( (vm) => {
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initMain); // Document still loading so DomContentLoaded can still fire :)
} else {
    initMain();
}
