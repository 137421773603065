<template>
    <div class="location-detail-map">
        <MglMap :accessToken="accessToken" :mapStyle.sync="mapStyle" :minZoom="2" :maxZoom="22" :attributionControl="false" @load="onSmallMapLoaded">
            <MglNavigationControl position="top-right" />
            <MglMarker :coordinates="[this.lng, this.lat]" anchor="bottom" color="#8A2432"></MglMarker>
        </MglMap>
    </div>
</template>

<script>
    import 'mapbox-gl/dist/mapbox-gl.css';
    import mapboxgl from "mapbox-gl";
    import Mapbox from "mapbox-gl";
    import { MglMap, MglMarker, MglNavigationControl } from "vue-mapbox";
    export default {
        name: "smallMap",
        components: {
            MglMap,
            MglMarker,
            MglNavigationControl,
        },
        props: ['lat', 'lng'],
        data() {
            return {
                accessToken: 'pk.eyJ1Ijoic2Fzc29hZ2VuY3kiLCJhIjoiY2syZjUyNmdvMGdubzNubW0xdDY5cmVjMCJ9.2JDn6K1RPPPFE-Lh1nYNHA', // your access token. Needed if you using Mapbox maps
                mapStyle: 'mapbox://styles/sassoagency/ck39rdleb0ikg1cm9yghher3b', // your map style
                mapBounds: null,
            };
        },
        methods: {
            onSmallMapLoaded(event) {
                // in component
                console.log('firing');
                let smMap = event.map;
                console.log('firing');
                smMap.jumpTo({center:[this.lng, this.lat], zoom: 15});

            }
        }
    }
</script>