<template>
    <transition :name="currentanim" mode="out-in">
        <div class="w-full px-6" v-show="this.slideid == currentslide">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "ProjectSlide",
        props: ['slideid','currentanim', 'currentslide'],
    }
</script>
