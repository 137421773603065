<template>
    <transition appear appear-class="fade-enter" appear-to-class="fade-enter-to" appear-active-class="fade-enter-active" v-on:after-appear="sequenceStart">
        <div class="home-page-banner" v-touch:swipe.left="nextClick"  v-touch:swipe.right="prevClick">
            <slot name="slides" :currentslide="this.currentslide" :currentanim="this.currentanim"></slot>
            <div class="slide-content-box-wrapper">
                <slot name="slidetext" :currentslide="this.currentslide"></slot>
                <div class="control-wrapper" v-if="this.slidecount > 1">
                    <button class="btn-prev-arrow" title="Previous Project" @click="prevClick">
                        <svg class="left-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M20.01,0l0,78.76l-20.01,-39.357l20.01,-39.403Z"></path></svg>
                    </button>
                    <button class="btn-next-arrow" title="Next Project" @click="nextClick">
                        <svg class="right-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,0l0,78.76l20.01,-39.357l-20.01,-39.403Z" ></path></svg>
                    </button>
                </div>
                <div class="slide-content-bg"></div>
            </div>
            <img src="/assets/images/mmrlogo-gray-300.svg" class="homebanner-bg">
        </div>
    </transition>
</template>

<script>
    let slideTimer = null;
    export default {
        name: "homeCarousel",
        data() {
            return {
                currentslide: null,
                currentanim: 'fade-slide-left',
            };
        },
        props: ['slidecount'],
        methods: {
            nextClick: function() {
                this.nextSlide();
                clearInterval(slideTimer);
                slideTimer = setInterval(this.nextSlide, 8000);
            },
            prevClick: function() {
                this.prevSlide();
                clearInterval(slideTimer);
                slideTimer = setInterval(this.nextSlide, 8000);
            },
            nextSlide: function() {
                this.currentanim = 'fade-slide-left';
                if (this.currentslide == (this.slidecount - 1)) {
                    this.currentslide = 0;
                } else {
                    this.currentslide++;
                }
            },
            prevSlide: function() {
                this.currentanim = 'fade-slide-right';
                if (this.currentslide == 0) {
                    this.currentslide = this.slidecount - 1;
                } else {
                    this.currentslide--;
                }
            },
            sequenceStart: function() {

                this.currentslide = 0;
                slideTimer = setInterval(this.nextSlide, 8000);

            }
        }

    }
</script>