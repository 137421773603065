export default {
    en: {
        general: {
            submitting: "Submitting"
        },
        contactForm: {
            firstName: "First Name",
            lastName: "Last Name",
            fromEmail: "Email Address",
            email: "Email Address",
            companyName: "Company Name",
            phone: "Phone Number",
            message: "Message",
            submitted: "Thank You for Contacting MMR",
            sentInfo: "Your message has been sent. We will go over it and get back in touch with you shortly.",
            return: "Return to the Contact Form"
        },
        philanthropyForm: {
            firstName: "First Name",
            lastName: "Last Name",
            fromEmail: "Email Address",
            email: "Email Address",
            companyName: "Company/Organization/Cause",
            phone: "Phone Number",
            website: "Website",
            federalEmployerId: "Federal Employer ID (FEIN or EIN)",
            is501c3: "Is this a 501(c)(3) Organization?",
            documentationFor501c3: "Please Attach Your 501(c)(3) Documentation",
            message: "Message",
            submitted: "Thank You for Contacting MMR",
            sentInfo: "Your message has been sent. We will review your request and get back in touch with you shortly.",
            return: "Return to the Philanthropy Form"
        },
        powerSolutionsForm: {
            firstName: "First Name",
            lastName: "Last Name",
            fromEmail: "Email Address",
            email: "Email Address",
            companyName: "Company or Organization Name",
            phone: "Phone Number",
            roleTitle: "Role/Title",
            typeOfInquiryPS: "Type of Inquiry",
            message: "Message",
            submitted: "Thank You for Contacting MMR Power Solutions",
            sentInfo: "Your message has been sent. We will review your request and get back in touch with you shortly.",
            return: "Return to the Contact Form"
        },
        missionCriticalForm: {
            firstName: "First Name",
            lastName: "Last Name",
            fromEmail: "Email Address",
            email: "Email Address",
            companyName: "Company or Organization Name",
            phone: "Phone Number",
            roleTitle: "Role/Title",
            typeOfInquiryPS: "Type of Inquiry",
            message: "Message",
            submitted: "Thank You for Contacting MMR's Mission Critical Construction Group",
            sentInfo: "Your message has been sent. We will review your request and get back in touch with you shortly.",
            return: "Return to the Contact Form"
        },
        internshipForm: {
            firstName: "First Name",
            lastName: "Last Name",
            instructions: "If you are interested in an internship at MMR, please provide the requested information on the form below.",
            email: "Email Address",
            streetAddress: "Street Address",
            city: "City",
            state: "State",
            zipCode: "Zip Code",
            phone: "Phone Number",
            university: "What University Do You Attend?",
            universityLocation: "Where Is Your University?",
            minor: "Minor",
            major: "Major",
            gpa: "GPA in Major",
            yearInProgram: "Year In Program",
            resume: "Please Upload Your Resumé",
            transcript: "Please Upload Your Transcript",
            coverLetter: "Please Upload Your Cover Letter",
            requestedDepartment: "Which Department(s) Are You Interested In?",
            districtOfficeDesired: "Which District Office(s) Are You Interested In?",
            haveYouWorkedForMmrBefore: "Have You Worked for MMR Before?",
            doYouHaveAnActiveTwicCard: "Do You Have An Active Twic Card? TWIC is required prior to employment for Communications, Estimating, and Project Control positions.",
            submitted: "Thank You for Applying for an Internship with MMR",
            sentInfo: "Your application has been sent to our Internship Team for review. We will go over it and get back in touch with you shortly.",
            return: "Return to the Internship Form"
        },
        error: {
            invalidFields: "Following fields have an invalid or a missing value:",
            general: "An error happened during submit.",
            generalMessage: "Form sending failed due to technical problems. Try again later.",
            fieldRequired: "This field is required.",
            fieldInvalid: "This field is invalid or missing."
        }
    }
};
