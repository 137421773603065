<template>
    <transition name="fade" mode="out-in">
        <div class="slide-content" v-show="this.slideid == currentslide">
            <slot name="slideheader"></slot>
            <div class="slide-text-wrapper" :class="{ active: textOpen }">
                <slot name="slidetext"></slot>
            </div>
            <button class="slide-text-toggle mb-3" @click="textOpen = !textOpen">
                <span v-if="textOpen == false">Read Full Text...</span>
                <span v-if="textOpen">Hide Text</span>
            </button>
            <slot name="slidebutton"></slot>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "homeCarouselContent",
        data() {
            return {
                textOpen: false,
            };
        },
        props: ['slideid','currentslide'],
    }
</script>
