<template>
    <div class="timeline-item-wrapper">
        <button class="timeline-item" @click="timelineDetails">
            <div class="timeline-label">{{mylabel}}</div>
            <slot></slot>
        </button>
    </div>
</template>

<script>
    var vueRoot;
    export default {
        name: "timelineItem",
        props: ['mylabel'],
        methods: {
            timelineDetails(event) {
                const buttonClicked = event.target;
                const eventContent = buttonClicked.getElementsByClassName('timeline-content').item(0).innerHTML;
                vueRoot.modalContent = eventContent;
                vueRoot.tlModal = true;
                vueRoot.modalActive = true;
            }
        },
        created() {
            vueRoot = this.$root;
        }
    }
</script>