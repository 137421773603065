<template>
    <div class="contact-form-panel">
        <div v-if="isSubmitted">
            <div class="row">
                <div class="w-full text-left p-6">

                    <h3 class="mb-4 text-red font-avenir font-extrabold uppercase text-2xl">{{ $t('contactForm.submitted' ) }}</h3>

                    <p class="mb-4">{{ $t('contactForm.sentInfo' ) }}</p>

                    <p class="text-lg">
                        <a href="#" class="btn-text" @click.prevent="reload()">{{ $t('contactForm.return' ) }}</a>
                    </p>

                </div>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="px-6 py-3 w-full">
                    <label>Are you a(n)?</label>
                    <select class="form-control" v-model.lazy.trim="userGroup" @change="groupCheck">
                        <option value="null" selected>Select One...</option>
                        <option value="1">Employee</option>
                        <option value="2">Job Seeker</option>
                        <option value="3">Client/Business Owner</option>
                        <option value="4">Vendor/Supplier</option>
                        <option value="5">California resident with a personal information request</option>
                        <option value="5">European resident with a personal information request</option>
                        <option value="6">Other</option>
                    </select>
                </div>
            </div>

            <div v-if="userGroup != null && userGroup != 2 && userGroup != 3" class="row">
                <div class="px-6 py-3 w-full">
                    <label>What is the Nature of Your Inquiry?</label>
                    <select class="form-control" v-model.lazy.trim="department" @change="setType($event)">
                        <option value="null" selected>Select One...</option>
                        <option v-if="userGroup == 1" value="a">Questions about Employment Dates</option>
                        <option v-if="userGroup == 1" value="b">Employment Verification</option>
                        <option v-if="userGroup == 1" value="c">Verification of Hours for Licensing</option>
                        <option v-if="userGroup == 1" value="b">Copy of Paycheck</option>
                        <option v-if="userGroup == 1" value="b">Copy of W-2</option>
                        <option v-if="userGroup == 1" value="b">Change of Address on File</option>
                        <option v-if="userGroup == 1" value="d">Garnishments</option>
                        <option v-if="userGroup == 1" value="e">Unemployment</option>
                        <option v-if="userGroup == 1" value="f">Health Insurance</option>
                        <option v-if="userGroup == 1" value="g">Leave (Vacation/Sick)</option>
                        <option v-if="userGroup == 1" value="m">401K/Profit Sharing</option>
                        <option v-if="userGroup == 4" value="h">Becoming a Vendor/Supplier</option>
                        <option v-if="userGroup == 4" value="i">Invoices & Payment</option>
                        <option v-if="userGroup == 5" value="l">Export My Personal Info</option>
                        <option v-if="userGroup == 5" value="l">Erase My Personal Info</option>
                        <option v-if="userGroup == 5" value="l">Other</option>
                        <option v-if="userGroup == 6" value="j">Community Outreach/Donation Request</option>
                        <option v-if="userGroup == 6" value="k">General Questions/Inquiry</option>
                    </select>
                    <div v-if="isErrorField('recipients')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.recipients') }) }}</div>
                </div>
            </div>
            <div v-if="userGroup == 2">
                <div class="row">
                    <div class="w-full text-left p-6">
                        <p>If you are interested in a position at MMR, <a href="/workforce-development/careers"><b>please visit our careers section.</b></a></p>
                        <p>You may also contact one of <a href="/locations"><b>MMR&rsquo;s District Offices</b></a> to schedule an appointment.
                        <p>These items are required to complete an employment application:</p>
                        <ul class="bullet-list">
                            <li>2 forms of government (state or federal) identification</li>
                            <li>TWIC card if required</li>
                            <li>Void check or bank set-up form for direct deposit</li>
                            <li>Social Security Card</li>
                            <li>Spouse and dependents date of birth and social security number if elect to enroll in the insurance program</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="department != null">
                <div v-if="userGroup != 2">
                    <div v-if="department == 'j'">
                        <div class="row">
                            <div class="w-full text-left p-6">
                                <p>If you are looking for MMR&rsquo;s support in a community outreach effort or for MMR to make a donation, <a href="/about-mmr/philanthropy"><b>please fill out our donation request form.</b></a></p>
                            </div>
                        </div>
                    </div>

                    <div v-if="department == 'g'">
                        <div class="row">
                            <div class="w-full text-left p-6">
                                <p>Please report to your immediate supervisor for information.</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="department != 'j' && department != 'g' && department != null">

                        <form @submit.prevent="submit" method="post" novalidate>

                            <div class="row">
                                <div class="px-6 py-3 w-full">
                                    <p v-if="userGroup == 3">Ready to work with the industry leader? Inquire about MMR's services to meet your project needs.</p>
                                    <p v-if="userGroup != 3">Please fill out the form below.</p>
                                    <p>* Required Field</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="px-6 py-3 w-full md:w-1/2">
                                    <label for="firstName">{{ $t('contactForm.firstName') }}*</label>
                                    <input type="text" class="form-control" id="firstName" v-model.lazy.trim="form.firstName" @blur="onFieldBlur('firstName')" v-bind:class="getFieldClasses('firstName')">
                                    <div v-if="isErrorField('firstName')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('contactForm.firstName') }) }}</div>
                                </div>

                                <div class="px-6 py-3 w-full md:w-1/2">
                                    <label for="lastName">{{ $t('contactForm.lastName') }}*</label>
                                    <input type="text" class="form-control" id="lastName" v-model.lazy.trim="form.lastName" @blur="onFieldBlur('lastName')" v-bind:class="getFieldClasses('lastName')">
                                    <div v-if="isErrorField('lastName')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('contactForm.lastName') }) }}</div>
                                </div>
                            </div>

                            <div class="row" v-if="userGroup != 1 && userGroup != 2 && userGroup != 5">
                                <div class="px-6 py-3 w-full">
                                    <label for="companyName">{{ $t('contactForm.companyName') }}<span v-if="userGroup == 3 || userGroup == 4">*</span></label>
                                    <input type="text" class="form-control" id="companyName" v-model.lazy.trim="form.companyName" @blur="onFieldBlur('companyName')" v-bind:class="getFieldClasses('companyName')">
                                    <div v-if="isErrorField('companyName')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.companyName') }) }}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="px-6 py-3 w-full md:w-1/2">
                                    <label for="email">{{ $t('contactForm.email') }}*</label>
                                    <input type="email" class="form-control" id="email" v-model.lazy.trim="form.email" @blur="onFieldBlur('email')" v-bind:class="getFieldClasses('email')">
                                    <div v-if="isErrorField('email')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.email') }) }}</div>
                                </div>

                                <div class="px-6 py-3 w-full md:w-1/2">
                                    <label for="phone">{{ $t('contactForm.phone') }}*</label>
                                    <input type="text" class="form-control" id="phone" v-model.lazy.trim="form.phone" @blur="onFieldBlur('phone')" v-bind:class="getFieldClasses('phone')">
                                    <div v-if="isErrorField('phone')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('contactForm.phone') }) }}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="px-6 py-3 w-full">
                                    <label for="message">{{ $t('contactForm.message') }}*</label>
                                    <textarea
                                            type="message"
                                            class="form-control"
                                            id="message"
                                            v-model.trim="form.message"
                                            v-bind:class="getFieldClasses('message')"
                                            @blur="onFieldBlur('message')">
                                    </textarea>
                                    <div v-if="isErrorField('message')" class="error-messages">{{  $t('error.fieldInvalid', { field: $t('contactForm.message') }) }}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="px-6 py-3 w-full text-lg">
                                    <button class="btn-arrow" :disabled="submitting">
                                        Submit
                                        <svg class="right-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,0l0,78.76l20.01,-39.357l-20.01,-39.403Z" ></path></svg>
                                    </button>
                                    <div v-if="submitting" class="px-1 pt-2"><img src="/assets/images/ajax-loader2.gif" class="inline"><br>Submitting form, please wait.</div>
                                    <p v-if="isError" class="pt-3 px-1 text-red-light font-bold">Your submission has errors in it. Please review the form and try again.</p>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import axios from 'axios';
    //import {mask} from 'vue-the-mask';
    //import { InputFacade, facade, filter } from 'vue-input-facade';

    const emailList = {
        'a': ['employmentdates@mmrgrp.com'],
        'b': ['employmentrecords@mmrgrp.com'],
        'c': ['licensinghours@mmrgrp.com'],
        'd': ['garnishments@mmrgrp.com'],
        'e': ['unemployment@mmrgrp.com'],
        'f': ['healthinsurance@mmrgrp.com'],
        'h': ['vendors@mmrgrp.com'],
        'i': ['invoices@mmrgrp.com'],
        'k': ['webinquiry@mmrgrp.com'],
        'l': ['privacyquestions@mmrgrp.com'],
        'm': ['profitsharing@mmrgrp.com']
    }

    export default {
        name: 'contact-form',
        data() {
            return {
                userGroup: null,
                department: null,
                isSubmitted: false,
                isError: false,
                errorHeader: 'error.invalidFields',
                errors: [],
                submitting: false,
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    companyName: '',
                    'departmentRecipient[]': '',
                    phone: '',
                    message: '',
                    formHash: '',
                    hptName: '',
                    hptHash: '',
                    requestType: ''
                }
            }
        },
        created: function () {
            // `this` points to the vm instance
            const self = this;

            function getFormHash() {
                return axios.get('/forms/formhashgetter/contactForm');
            }

            function getHptName() {
                return axios.get('/forms/hptnamegetter/contactForm');
            }

            function getHptHash() {
                return axios.get('/forms/hpthashgetter/contactForm');
            }

            axios.all([getFormHash(), getHptName(), getHptHash()])
                .then(axios.spread(function (fHash, hName, hHash) {

                    // Both requests are now complete
                    self.form.formHash = fHash.data.trim();
                    self.form.hptName = hName.data.trim();
                    self.form.hptHash = hHash.data.trim();
                    console.log(self.form.formHash);
                    console.log(self.form.hptName);
                    console.log(self.form.hptHash);
                }));
        },
        /*
        components: { InputFacade },
        directives: { facade },
        filters: { facade: filter },
         */
        //directives: { mask },
        methods: {
            groupCheck() {
                if (this.userGroup == 3) {
                    this.department = 'k';
                } else {
                    if (this.userGroup != 2) {
                        this.department = null;
                    }
                }
            },
            setType(event) {
                const sel = event.target;
                this.form.requestType = sel.options[sel.selectedIndex].text;
            },
            submit() {
                this.$v.$touch();
                if (!this.$v.$error) {
                    this.sendFormData();
                } else {
                    this.validationError();
                }
            },
            enableSubmitLoader() {
                this.submitting = true;
            },
            disableSubmitLoader() {
                this.submitting = false;
            },
            sendFormData() {
                this.enableSubmitLoader();
                let formData = new FormData();
                let formInput = this.form;
                formData.append('action', 'freeform/api/form');
                formData.append(this.$parent.csrfName, this.$parent.csrfTokenValue);
                formData.append('formHash', this.form.formHash);
                formData.append(this.form.hptName, this.form.hptHash);
                this.form['departmentRecipient[]'] = emailList[this.department];
                //this.form.dynamicNotification.recipients = emailList[this.department];
                Object.keys(formInput).forEach(function (item) {
                    formData.append(item, formInput[item]);
                });
                //let formData = JSON.stringify(this.form);
                axios.post('/', formData, {
                    headers: {
                        'X-CSRF-Token': this.$parent.csrfTokenValue,
                        "X-Requested-With": "XMLHttpRequest",
                        "HTTP_X_REQUESTED_WITH": "XMLHttpRequest",

                    }
                }).then(response => {
                    this.submitSuccess(response);
                    this.disableSubmitLoader();
                }).catch(error => {
                    this.submitError(error);
                    this.disableSubmitLoader();
                });

            },
            submitSuccess(response) {
                if (response.data.success) {
                    this.isSubmitted = true;
                    this.isError = false;
                } else {
                    this.errorHeader = 'error.invalidFields';
                    this.errors = response.data.errors;
                    this.isError = true;
                }
            },
            submitError(error) {
                this.errorHeader = 'error.general';
                this.errors = [{'field': null, 'message': 'error.generalMessage'}];
                this.isError = true;
            },
            validationError() {
                this.errorHeader = 'error.invalidFields';
                this.errors = this.getErrors();
                this.isError = true;
            },
            isErrorField(field) {
                try {
                    if (this.getValidationField(field).$error) {
                        return true;
                    }
                } catch (error) {}

                return this.errors.some(el => el.field === field);
            },
            getErrors() {
                let errors = [];
                for (const field of Object.keys(this.form)) {
                    try {
                        if (this.getValidationField(field).$error) {
                            errors.push({'field': field, 'message': null});
                        }
                    } catch (error) {}
                }
                return errors;
            },
            getFieldClasses(field) {
                return { 'is-invalid': this.isErrorField(field) }
            },
            getValidationField(field) {
                if (this.$v.form[field]) {
                    return this.$v.form[field];
                }
                throw Error('No validation for field ' + field);
            },
            onFieldBlur(field) {
                try {
                    this.getValidationField(field).$touch();
                    if (this.getValidationField(field).$error) {
                        if (!this.errors.some(el => el.field === field)) {
                            this.errors.push({'field': field, 'message': null});
                        }
                    } else {
                        this.errors = this.errors.filter(el => el.field !== field);
                    }
                } catch (error) {}
            },
            reload() {
                window.location = '';
            }
        },
        validations() {
            if (this.userGroup == 3 || this.userGroup == 4 ) {
                return {
                    form: {
                        email: { required, email },
                        firstName: { required },
                        lastName: { required },
                        companyName: { required },
                        phone: { required },
                        message: { required }
                    }
                }
            } else {
                return {
                    form: {
                        email: { required, email },
                        firstName: { required },
                        lastName: { required },
                        phone: { required },
                        message: { required }
                    }
                }
            }
        },
        watch: {
            errors() {
                this.isError = this.errors.length > 0 ? true : false;
            }
        }
    }
</script>
