<template>
    <div class="page-section pt-6 px-6 pb-20 bg-gray-100">
        <div class="container-fluid block-content">
            <div class="row justify-center items-stretch">
                <div class="project-highlight-outer">
                    <div class="row justify-center items-stretch">
                        <div class="w-full pt-6 px-6 pb-0 text-lg">
                            <h2 class="text-gray-700 uppercase text-3xl header-text-normal mb-0"><strong><em>Project Spotlight</em></strong></h2>
                            <p><a href="/experience" class="btn-text">View All Projects</a></p>
                        </div>
                        <div class="project-highlight-wrapper">
                            <slot :currentanim="this.currentanim" :currentslide="this.currentslide"></slot>
                        </div>
                    </div>
                    <div class="control-wrapper">
                       <button class="btn-prev-arrow" title="Previous Project" @click="prevSlide">
                           <svg class="left-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M20.01,0l0,78.76l-20.01,-39.357l20.01,-39.403Z"></path></svg>
                       </button>
                       <button class="btn-next-arrow" title="Next Project" @click="nextSlide">
                           <svg class="right-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,0l0,78.76l20.01,-39.357l-20.01,-39.403Z" ></path></svg>
                       </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "homeProjectFeature",
        data() {
            return {
                currentslide: null,
                currentanim: 'fade-slide-left',
            };
        },

        props: ['projectcount'],

        methods: {
            nextSlide: function() {
              this.currentanim = 'fade-slide-left';
              if (this.currentslide == (this.projectcount - 1)) {
                this.currentslide = 0;
              } else {
                this.currentslide++;
              }
            },
            prevSlide: function() {
                this.currentanim = 'fade-slide-right';
                if (this.currentslide == 0) {
                    this.currentslide = this.projectcount - 1;
                } else {
                    this.currentslide--;
                }
            },
        },
        mounted () {
            this.currentslide = 0;
        }

    }
</script>

