<template>
    <div class="locations-map-wrapper">
        <div class="locmap-link-panel" :class="{ active: panelUp }">
            <button class="loclist-panel-btn" :class="{ active: panelUp }" @click="panelUp = !panelUp">
                View Locations as List
                <div class="arrow-wrapper"></div>
            </button>
            <div class="locmap-link-panel-inner">
                <div class="flex flex-wrap bg-red text-sm md:text-base">
                    <div class="w-full md:w-1/2 lg:w-2/3 md:pr-3 loc-list-column mb-3">
                        <h3 class="uppercase font-avenir font-extrabold text-2xl text-white mb-2">United States</h3>
                        <ul class="list-none py-0 pr-0 pl-2 m-0 lg-columns-2">
                            <li v-for="location in countryLists['United States']">
                                <button class="btn-loc-list mb-2" @click="locationUp(location.id)">{{location.locationShortName}} - {{location.city}}, {{location.state}}</button>
                            </li>
                        </ul>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 md:pr-3 loc-list-column">
                        <div class="mb-3" v-for="(country, key) in countryLists" v-if="key != 'United States'">
                            <h3 class="uppercase font-avenir font-extrabold text-2xl text-white mb-2">{{key}}</h3>
                            <ul class="list-none p-0 m-0">
                                <li v-for="location in country">
                                    <button class="btn-loc-list mb-2" @click="locationUp(location.id)">{{location.locationShortName}} - {{location.city}}, {{location.state}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MglMap :accessToken="accessToken" :mapStyle.sync="mapStyle" :minZoom="2" :maxZoom="22" :attributionControl="false" :scrollZoom="false" :dragRotate="false" @load="onMapLoaded">
            <MglNavigationControl position="top-right" :showCompass="false" />

            <MglMarker v-for="marker in pullResults" :coordinates="marker.coordinates" anchor="bottom" color="#F8C700" class="marker">
                <MglPopup anchor="bottom" :offset="[0,-50]" >
                    <div class="marker-popup-content-wrapper">
                        <div class="w-1/3">
                            <picture>
                                <source v-bind:srcset="marker.featuredImage[0].thumbOpts.srcsetWebP"
                                        sizes="75px"
                                        type="image/webp" />
                                <img v-bind:src="marker.featuredImage[0].thumbOpts.src"
                                     v-bind:srcset="marker.featuredImage[0].thumbOpts.srcset"
                                     sizes="75px"
                                     class="map-marker-thumb"
                                />
                            </picture>
                        </div>
                        <div class="w-2/3 pl-2 text-xs">
                            <h4 class="text-red uppercase mb-1 text-xs font-avenir font-extrabold">{{marker.locationShortName}}</h4>
                            <p class="mb-1 font-avenir text-xs font-extrabold uppercase text-gray-700">{{marker.city}}, {{marker.state}}</p>
                            <button class="btn-text" @click="locationUp(marker.id)">View Details</button>
                        </div>
                    </div>
                </MglPopup>
            </MglMarker>

        </MglMap>
    </div>
</template>

<script>
    import axios from 'axios';
    import mapboxgl from "mapbox-gl";
    import Mapbox from "mapbox-gl";
    import { MglMap, MglNavigationControl, MglPopup, MglMarker } from "vue-mapbox";
    const qlToken = 'gG0bSOrwD6Zhu0tqkbIb8QUgf3GPHUhnK518hNzjcVXfE7Fwy1oLGTA0zMSDhuzT';
    const qlUrl = '/api';

    const pullQuery =
        `
        query fetchLocations {
          entries(section:[locations]) {
            ...on Locations {
              id
              title
              locationShortName
              locationCityName
              addressAndLocation {
                lat
                lng
                address
              }
              phoneNumber {
                customText
                url
              }
              tollFreeNumber  {
                customText
                url
              }
              faxNumber {
                customText
                url
              }
              faxNumber {
                link
                customText
                url
              }
              textContent {
                content
              }
              featuredImage {
                ...on BannersVolume {
                    title
                    thumbOpts {
                        ...on OptimizedImagesData {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp
                        }
                    }
                }
              }
            }
          }
        }
        `
    ;

    const imageBackupQuery =
        `
        query fetchHQPhoto {
          entries(section:[locations], id:2859) {
            ...on Locations {
              featuredImage {
                ...on BannersVolume {
                    title
                    thumbOpts {
                        ...on OptimizedImagesData {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp
                        }
                    }
                }
              }
            }
          }
        }
        `
    ;
    var vueRoot = null;
    const configureApi = (url, token) => {
        return {
            baseURL: url,
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        };
    };

    const executeQuery = (api, query, callback) => {
        api.post('', {
            query: query
        }).then((result) => {
            if (callback) {
                callback(result.data);
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    const getDetails = (locId) => {
        var locationUrl = '/locations/details/' + locId;
        return axios.get(locationUrl);
    }

    //var map;

    export default {
        name: "locationsMap",
        components: {
            MglMap,
            MglNavigationControl,
            MglMarker,
            MglPopup
        },
        data() {
            return {
                accessToken: 'pk.eyJ1Ijoic2Fzc29hZ2VuY3kiLCJhIjoiY2syZjUyNmdvMGdubzNubW0xdDY5cmVjMCJ9.2JDn6K1RPPPFE-Lh1nYNHA', // your access token. Needed if you using Mapbox maps
                mapStyle: 'mapbox://styles/sassoagency/ck2glh2sk0za81cmn43se2nel', // your map style
                panelUp: false,
                dataApi: null,
                pullResults: [],
                backupImgData: null,
                resultData: null,
                mapBounds: null,
                countryLists: {},
                locsLoaded: false,
            };
        },
        methods: {
            loadBackupPhoto: function() {
                executeQuery(this.dataApi, imageBackupQuery, (data) => {
                    this.backupImgData = data;
                    console.log('Backup Image Data');
                    console.log(this.backupImgData);
                });
            },
            loadLocations: function() {
                executeQuery(this.dataApi, pullQuery, (data) => {
                    this.resultData = data;
                    console.log(this.resultData);

                    var i;
                    for (var nodeItem of this.resultData.data.entries) {

                        nodeItem.coordinates = [nodeItem.addressAndLocation.lng, nodeItem.addressAndLocation.lat];
                        nodeItem.llObj = new mapboxgl.LngLat(nodeItem.addressAndLocation.lng, nodeItem.addressAndLocation.lat);

                        nodeItem.addressComps = nodeItem.addressAndLocation.address.split(',');
                        if (nodeItem.locationCityName == null) {
                            nodeItem.city = nodeItem.addressComps[2].trim();
                        } else {
                            nodeItem.city = nodeItem.locationCityName;
                        }

                        nodeItem.state = nodeItem.addressComps[4].trim();
                        nodeItem.country = nodeItem.addressComps[5].trim();

                        if (nodeItem.featuredImage.length == 0) {
                            nodeItem.featuredImage = this.backupImgData.data.entries[0].featuredImage;
                        }
                        this.pullResults.push(nodeItem);
                        console.log(nodeItem.city + '' + nodeItem.coordinates);
                        this.mapBounds.extend(nodeItem.llObj);

                    }

                    console.log(this.pullResults);
                    for (i = 0; i < this.pullResults.length; i++) {

                        if (!this.countryLists.hasOwnProperty(this.pullResults[i].country)) {
                            this.countryLists[this.pullResults[i].country] = [];
                        }
                        this.countryLists[this.pullResults[i].country].push(this.pullResults[i]);
                    }
                    const topLevelList = this.countryLists;
                    Object.keys(topLevelList).forEach(function(k) {
                        topLevelList[k].sort(function(a, b) {
                            if (b.city == 'Baton Rouge' )
                                return 1;
                            if (a.city == 'Baton Rouge' )
                                return -1;
                            if (a.locationShortName < b.locationShortName)
                                return -1;
                            if (a.locationShortName > b.locationShortName)
                                return 1;
                            if (a.state < b.state)
                                return -1;
                            if (a.state > b.state)
                                return 1;
                            if (a.city < b.city)
                                return -1;
                            if (a.city > b.city)
                                return 1;
                            return 0;
                        });
                    });
                    this.locsLoaded = true;
                    console.log("locations loaded");

                    if (this.map != null) {
                        console.log("from location load map not null, setting bounds");
                        this.map.fitBounds(this.mapBounds,{padding: 150});
                    }

                });

            },
            locationUp: function(itemId) {
                vueRoot.modalContent = '<div class="loadspinner-locations"></div>';
                vueRoot.modalActive = true;
                getDetails(itemId).then(function (response) {
                    vueRoot.modalContent = response.data;
                }).catch(function (response) {
                    console.log(response);
                });
            },
            onMapLoaded(event) {
                // in component
                console.log('map loaded');
                this.map = event.map;
                if (this.locsLoaded) {
                    console.log("from maploaded, locsLoaded tests true, setting bounds");
                    console.log(this.mapBounds);
                    this.map.fitBounds(this.mapBounds,{padding: 150});
                }
            }
        },
        created() {
            // We need to set mapbox-gl library here in order to use it in template
            this.mapbox = Mapbox;
            this.dataApi = axios.create(configureApi(qlUrl, qlToken));
            this.loadBackupPhoto();
            this.loadLocations();
            this.mapBounds = new mapboxgl.LngLatBounds();
            this.map = null;
            vueRoot = this.$parent;
        }
    }
</script>
