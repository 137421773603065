<template>
    <transition :name="currentanim" mode="out-in">
        <div class="home-carousel-slide" v-show="this.slideid == currentslide">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "homeCarouselSlide",
        props: ['slideid','currentslide', 'currentanim'],
    }
</script>
