<template>
    <div class="mb-6 accordion">
        <button class="accordion-button" @click="panelAction()" :aria-expanded="this.isExpanded" :class="{expanded: this.isExpanded}" :aria-controls="this.panelId" :id="this.buttonId" type="button" >
            <div class="accordion-icon">
                <svg viewBox="0 0 12 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M3.085,0l2.908,7.235l-2.908,7.234l-3.085,0l2.907,-7.234l-2.907,-7.235l3.085,0Zm5.993,0l2.908,7.235l-2.908,7.234l-3.085,0l2.907,-7.234l-2.907,-7.235l3.085,0Z" style="fill:currentColor;fill-rule:nonzero;"></path></svg>
            </div>
            {{ this.title }}
        </button>
        <div class="accordion-panel-wrapper" :class="{expanded: this.isExpanded}" :aria-labelledby="this.buttonId" role="region" >
            <div class="accordion-panel bg-gray-300 ">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "accordion",
        data() {
            return {
                isExpanded: false,
                buttonId : this.identifier + '-button',
                panelId : this.identifier + '-panel',
            }
        },
        props: ['title', 'identifier'],
        methods: {
            panelAction: function() {
                console.log('click');
                this.isExpanded = !this.isExpanded;
            }
        }
    }
</script>