<template>
    <div class="internship-form-panel">
        <div v-if="isSubmitted">
            <div class="row">
                <div class="w-full text-left p-6">

                    <h3 class="mb-4 text-red font-avenir font-extrabold uppercase text-2xl">{{ $t('internshipForm.submitted' ) }}</h3>

                    <p class="mb-4">{{ $t('internshipForm.sentInfo' ) }}</p>

                    <p class="text-lg">
                        <a href="#" class="btn-text" @click.prevent="reload()">{{ $t('internshipForm.return' ) }}</a>
                    </p>

                </div>
            </div>
        </div>

        <div v-else>

            <form @submit.prevent="submit" id="internForm" method="post" novalidate>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <p class="text-center">{{ $t('internshipForm.instructions') }}</p>
                        <p class="text-center">* Required Field</p>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="firstName">{{ $t('internshipForm.firstName') }}*</label>
                        <input type="text" class="form-control" id="firstName" v-model.lazy.trim="form.firstName" @blur="onFieldBlur('firstName')" v-bind:class="getFieldClasses('firstName')">
                        <div v-if="isErrorField('firstName')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.firstName') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="lastName">{{ $t('internshipForm.lastName') }}*</label>
                        <input type="text" class="form-control" id="lastName" v-model.lazy.trim="form.lastName" @blur="onFieldBlur('lastName')" v-bind:class="getFieldClasses('lastName')">
                        <div v-if="isErrorField('lastName')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.lastName') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="email">{{ $t('internshipForm.email') }}*</label>
                        <input type="email" class="form-control" id="email" v-model.lazy.trim="form.email" @blur="onFieldBlur('email')" v-bind:class="getFieldClasses('email')">
                        <div v-if="isErrorField('email')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.email') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/2">
                        <label for="phone">{{ $t('internshipForm.phone') }}</label>
                        <input type="text" class="form-control" v-mask="'(###)###-####'" id="phone" v-model.lazy.trim="form.phone" @blur="onFieldBlur('phone')" v-bind:class="getFieldClasses('phone')">
                        <div v-if="isErrorField('phone')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.phone') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="streetAddress">{{ $t('internshipForm.streetAddress') }}</label>
                        <input type="text" class="form-control" id="streetAddress" v-model.lazy.trim="form.streetAddress" @blur="onFieldBlur('streetAddress')" v-bind:class="getFieldClasses('streetAddress')">
                        <div v-if="isErrorField('streetAddress')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.streetAddress') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="city">{{ $t('internshipForm.city') }}</label>
                        <input type="text" class="form-control" id="city" v-model.lazy.trim="form.city" @blur="onFieldBlur('city')" v-bind:class="getFieldClasses('city')">
                        <div v-if="isErrorField('city')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.city') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="state">{{ $t('internshipForm.state') }}</label>
                        <select class="form-control" id="state" v-model.lazy.trim="form.state" @blur="onFieldBlur('state')" v-bind:class="getFieldClasses('state')">
                            <option value="null" selected>Select a State...</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <div v-if="isErrorField('state')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.state') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="zipCode">{{ $t('internshipForm.zipCode') }}</label>
                        <input type="text" class="form-control" id="zipCode" v-model.lazy.trim="form.zipCode" @blur="onFieldBlur('zipCode')" v-bind:class="getFieldClasses('zipCode')">
                        <div v-if="isErrorField('zipCode')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.zipCode') }) }}</div>
                    </div>

                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="resume">{{ $t('internshipForm.resume') }}*</label>
                        <p class="mb-1">PDF files or a Word Documents 2MB or smaller are allowed.</p>
                        <input type="file" class="form-control" id="resume" ref="resume" v-on:change="onChangeResume()" @blur="onFieldBlur('resume')" v-bind:class="getFieldClasses('resume')">
                        <div v-if="isErrorField('resume')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.resume') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="transcript">{{ $t('internshipForm.transcript') }}</label>
                        <p class="mb-1">PDF files or a Word Documents 2MB or smaller are allowed.</p>
                        <input type="file" class="form-control" id="transcript" ref="transcript" v-on:change="onChangeTranscript()" @blur="onFieldBlur('transcript')" v-bind:class="getFieldClasses('transcript')">
                        <div v-if="isErrorField('transcript')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.transcript') }) }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="coverLetter">{{ $t('internshipForm.coverLetter') }}</label>
                        <p class="mb-1">PDF files or a Word Documents 2MB or smaller are allowed.</p>
                        <input type="file" class="form-control" id="coverLetter" ref="coverLetter" v-on:change="onChangeCoverLetter()" @blur="onFieldBlur('coverLetter')" v-bind:class="getFieldClasses('coverLetter')">
                        <div v-if="isErrorField('coverLetter')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.coverLetter') }) }}</div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <h3 class="uppercase font-avenir font-extrabold text-2xl">Internship Information</h3>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="requestedDepartment">{{ $t('internshipForm.requestedDepartment') }}*</label>
                        <div class="row">
                            <div class="w-full md-columns-2">
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-Accounting" value="Accounting Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Accounting Department</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-Communications" value="Communications Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Communications Department</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-Estimating" value="Estimating Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Estimating Department</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-HumanResources" value="Human Resources Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Human Resources Department</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-IT" value="Information Technology Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Information Technology Department</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-ProjectControls" value="Project Controls Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Project Controls Department</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" id="requestedDepartment-WorkforceDevelopment" value="Workforce Development Department" v-model.lazy.trim="form.requestedDepartment" @blur="onFieldBlur('requestedDepartment')" v-bind:class="getFieldClasses('requestedDepartment')">
                                    <label class="radio-label">Workforce Development Department</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="isErrorField('requestedDepartment')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.requestedDepartment') }) }}</div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="doYouHaveAnActiveTwicCard">{{ $t('internshipForm.doYouHaveAnActiveTwicCard') }}*</label>
                        <div class="pt-1">
                            <input type="radio" class="form-control-radio" id="doYouHaveAnActiveTwicCard-yes" value="Yes" v-model.lazy.trim="form.doYouHaveAnActiveTwicCard" @blur="onFieldBlur('doYouHaveAnActiveTwicCard')" v-bind:class="getFieldClasses('doYouHaveAnActiveTwicCard')">
                            <label class="radio-label">Yes</label>
                            <input type="radio" class="form-control-radio" id="doYouHaveAnActiveTwicCard-no" value="No" v-model.lazy.trim="form.doYouHaveAnActiveTwicCard" @blur="onFieldBlur('doYouHaveAnActiveTwicCard')" v-bind:class="getFieldClasses('doYouHaveAnActiveTwicCard')">
                            <label class="radio-label">No</label>
                        </div>
                        <div v-if="isErrorField('doYouHaveAnActiveTwicCard')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.doYouHaveAnActiveTwicCard') }) }}</div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <label for="districtOfficeDesired">{{ $t('internshipForm.districtOfficeDesired') }}*</label>
                        <div class="row">
                            <div class="w-full md-columns-2 xl-columns-3">
                                <div v-for="loc in sortedLocations(this.locations)" class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" :value="loc.value" v-model.lazy.trim="form.districtOfficeDesired" @blur="onFieldBlur('districtOfficeDesired')" v-bind:class="getFieldClasses('districtOfficeDesired')">
                                    <label class="radio-label">{{loc.city}}, {{loc.state}}</label>
                                </div>
                                <div class="w-full pt-1 px-6">
                                    <input type="checkbox" class="form-control-radio" value="No Preference" v-model.lazy.trim="form.districtOfficeDesired" @blur="onFieldBlur('districtOfficeDesired')" v-bind:class="getFieldClasses('districtOfficeDesired')">
                                    <label class="radio-label">No Preference</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="isErrorField('districtOfficeDesired')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.districtOfficeDesired') }) }}</div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full lg:w-1/3">
                        <label for="haveYouWorkedForMmrBefore">{{ $t('internshipForm.haveYouWorkedForMmrBefore') }}*</label>
                        <div class="pt-1">
                            <input type="radio" class="form-control-radio" id="haveYouWorkedForMmrBefore-yes" value="Yes" v-model.lazy.trim="form.haveYouWorkedForMmrBefore" @blur="onFieldBlur('haveYouWorkedForMmrBefore')" v-bind:class="getFieldClasses('haveYouWorkedForMmrBefore')">
                            <label class="radio-label">Yes</label>
                            <input type="radio" class="form-control-radio" id="haveYouWorkedForMmrBefore-no" value="No" v-model.lazy.trim="form.haveYouWorkedForMmrBefore" @blur="onFieldBlur('haveYouWorkedForMmrBefore')" v-bind:class="getFieldClasses('haveYouWorkedForMmrBefore')">
                            <label class="radio-label">No</label>
                        </div>
                        <div v-if="isErrorField('haveYouWorkedForMmrBefore')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.haveYouWorkedForMmrBefore') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full lg:w-2/3">
                        <label>Anticipated Start Date</label>
                        <div class="row">
                            <div class="w-1/2 px-6">
                                <select class="form-control" id="startDateSemester" v-model.lazy.trim="form.startDateSemester" @blur="onFieldBlur('startDateSemester')" v-bind:class="getFieldClasses('startDateSemester')">
                                    <option value="null" selected>Semester</option>
                                    <option value="Fall">Fall</option>
                                    <option value="Spring">Spring</option>
                                    <option value="Summer">Summer</option>
                                </select>
                                <div v-if="isErrorField('startDateSemester')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.startDateSemester') }) }}</div>
                            </div>

                            <div class="w-1/2 px-6">
                                <select class="form-control" id="startDateYear" v-model.lazy.trim="form.startDateYear" @blur="onFieldBlur('startDateYear')" v-bind:class="getFieldClasses('startDateYear')">
                                    <option value="null" selected>Year</option>
                                    <option :value="year" selected>{{year}}</option>
                                    <option :value="1 + year">{{1 + year}}</option>
                                    <option :value="2 + year">{{2 + year}}</option>
                                </select>
                                <div v-if="isErrorField('startDateYear')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.startDateYear') }) }}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full">
                        <h3 class="uppercase font-avenir font-extrabold text-2xl">Education Information</h3>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full lg:w-1/2">
                        <label for="university">{{ $t('internshipForm.university') }}</label>
                        <input type="text" class="form-control" id="university" v-model.lazy.trim="form.university" @blur="onFieldBlur('university')" v-bind:class="getFieldClasses('university')">
                        <div v-if="isErrorField('university')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.university') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full lg:w-1/2">
                        <label for="universityLocation">{{ $t('internshipForm.universityLocation') }}</label>
                        <input type="text" class="form-control" id="universityLocation" v-model.lazy.trim="form.universityLocation" @blur="onFieldBlur('universityLocation')" v-bind:class="getFieldClasses('universityLocation')">
                        <div v-if="isErrorField('universityLocation')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.universityLocation') }) }}</div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="major">{{ $t('internshipForm.major') }}</label>
                        <input type="text" class="form-control" id="major" v-model.lazy.trim="form.major" @blur="onFieldBlur('major')" v-bind:class="getFieldClasses('major')">
                        <div v-if="isErrorField('major')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.major') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="minor">{{ $t('internshipForm.minor') }}</label>
                        <input type="text" class="form-control" id="minor" v-model.lazy.trim="form.minor" @blur="onFieldBlur('minor')" v-bind:class="getFieldClasses('minor')">
                        <div v-if="isErrorField('minor')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.minor') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="gpa">{{ $t('internshipForm.gpa') }}</label>
                        <input type="number" class="form-control" id="gpa" v-model.lazy.trim="form.gpa" @blur="onFieldBlur('gpa')" v-bind:class="getFieldClasses('gpa')">
                        <div v-if="isErrorField('universityLocation')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('internshipForm.gpa') }) }}</div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full md:w-1/3">
                        <label for="yearInProgram">{{ $t('internshipForm.yearInProgram') }}</label>
                        <select class="form-control" id="yearInProgram" v-model.lazy.trim="form.yearInProgram" @blur="onFieldBlur('yearInProgram')" v-bind:class="getFieldClasses('yearInProgram')">
                            <option value="null" selected>Choose One...</option>
                            <option value="Freshman">Freshman</option>
                            <option value="Sophomore">Sophomore</option>
                            <option value="Junior">Junior</option>
                            <option value="Senior">Senior</option>
                        </select>
                        <div v-if="isErrorField('yearInProgram')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.yearInProgram') }) }}</div>
                    </div>

                    <div class="px-6 py-3 w-full md:w-2/3">
                        <label>Anticipated Graduation Date</label>
                        <div class="row">
                            <div class="w-1/2 px-6">
                                <select class="form-control" id="graduationSemester" v-model.lazy.trim="form.graduationSemester" @blur="onFieldBlur('graduationSemester')" v-bind:class="getFieldClasses('graduationSemester')">
                                    <option value="null" selected>Semester</option>
                                    <option value="Fall">Fall</option>
                                    <option value="Spring">Spring</option>
                                    <option value="Summer">Summer</option>
                                </select>
                                <div v-if="isErrorField('graduationSemester')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.graduationSemester') }) }}</div>
                            </div>

                            <div class="w-1/2 px-6">
                                <select class="form-control" id="graduationYear" v-model.lazy.trim="form.graduationYear" @blur="onFieldBlur('graduationYear')" v-bind:class="getFieldClasses('graduationYear')">
                                    <option value="null" selected>Year</option>
                                    <option :value="year" selected>{{year}}</option>
                                    <option v-for="n in 5" :value="n + year">{{n + year}}</option>
                                </select>
                                <div v-if="isErrorField('graduationYear')" class="error-messages">{{ $t('error.fieldRequired', { field: $t('internshipForm.graduationYear') }) }}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="px-6 py-3 w-full text-lg text-center">
                        <button class="btn-hex" :disabled="submitting">
                            Submit
                            <svg class="right-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,0l0,78.76l20.01,-39.357l-20.01,-39.403Z" ></path></svg>
                            <svg class="left-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M20.01,0l0,78.76l-20.01,-39.357l20.01,-39.403Z"></path></svg>
                        </button>
                        <p v-if="submitting" class="text-center pt-2 px-1"><img src="/assets/images/ajax-loader2.gif" class="inline"><br>Submitting form, please wait.</p>
                        <p v-if="isError" class="text-center pt-3 px-1 text-red-light font-bold">Your submission has errors in it. Please review the form and try again.</p>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import axios from 'axios';
    import {mask} from 'vue-the-mask';

    export default {
        name: 'internship-form',
        data() {
            return {
                isSubmitted: false,
                isError: false,
                errorHeader: 'error.invalidFields',
                errors: [],
                year: Number(new Date().getFullYear()),
                submitting: false,
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    streetAddress: '',
                    city: '',
                    state: null,
                    zipCode: null,
                    phone: '',
                    districtOfficeDesired: [],
                    resume: '',
                    haveYouWorkedForMmrBefore: '',
                    doYouHaveAnActiveTwicCard: '',
                    university: '',
                    universityLocation: '',
                    minor: '',
                    major: '',
                    gpa: 0.0,
                    yearInProgram: null,
                    graduationSemester: null,
                    graduationYear: null,
                    startDateSemester: null,
                    startDateYear: null,
                    coverLetter: '',
                    transcript: '',
                    requestedDepartment: [],
                    formHash: '',
                    hptName: '',
                    hptHash: '',
                }
            }
        },
        props: ['locations'],
        created: function () {
            // `this` points to the vm instance
            const self = this;

            function getFormHash() {
                return axios.get('/forms/formhashgetter/internshipForm');
            }

            function getHptName() {
                return axios.get('/forms/hptnamegetter/internshipForm');
            }

            function getHptHash() {
                return axios.get('/forms/hpthashgetter/internshipForm');
            }

            axios.all([getFormHash(), getHptName(), getHptHash()])
                .then(axios.spread(function (fHash, hName, hHash) {

                    // Both requests are now complete
                    self.form.formHash = fHash.data.trim();
                    self.form.hptName = hName.data.trim();
                    self.form.hptHash = hHash.data.trim();
                }));
        },
        directives: {mask},
        methods: {
            sortedLocations: function(arr) {
                // Set slice() to avoid to generate an infinite loop!
                return arr.slice().sort(function(a, b) {
                    if (a.country < b.country)
                        return 1;
                    if (a.country > b.country)
                        return -1;
                    if (a.state < b.state)
                        return -1;
                    if (a.state > b.state)
                        return 1;
                    if (a.city < b.city)
                        return -1;
                    if (a.city > b.city)
                        return 1;
                    return 0;
                });
            },
            submit() {
                this.$v.$touch();
                if (!this.$v.$error) {
                    this.sendFormData();
                } else {
                    this.validationError();

                }
            },
            enableSubmitLoader() {
                this.submitting = true;
            },
            disableSubmitLoader() {
                this.submitting = false;
            },
            onChangeResume(){
                this.form.resume = this.$refs.resume.files[0];
            },
            onChangeTranscript(){
                this.form.transcript = this.$refs.transcript.files[0];
            },
            onChangeCoverLetter(){
                this.form.coverLetter = this.$refs.coverLetter.files[0];
            },
            sendFormData() {
                this.enableSubmitLoader();
                let formData = new FormData();
                let formInput = this.form;
                formData.append('action', 'freeform/api/form');
                formData.append(this.$parent.csrfName, this.$parent.csrfTokenValue);
                formData.append('formHash', this.form.formHash);
                formData.append(this.form.hptName, this.form.hptHash);
                Object.keys(formInput).forEach(function (item) {
                    formData.append(item, formInput[item]);
                });

                //let formData = JSON.stringify(this.form);
                axios.post('/', formData, {
                    headers: {
                        'X-CSRF-Token': this.$parent.csrfTokenValue,
                        "X-Requested-With": "XMLHttpRequest",
                        "HTTP_X_REQUESTED_WITH": "XMLHttpRequest",
                        'Content-Type': 'multipart/form-data',
                    }
                }).then(response => {
                    this.submitSuccess(response);
                    this.disableSubmitLoader();
                }).catch(error => {
                    this.submitError(error);
                    this.disableSubmitLoader();
                });

            },
            submitSuccess(response) {
                if (response.data.success) {
                    this.isSubmitted = true;
                    this.isError = false;
                } else {
                    this.errorHeader = 'error.invalidFields';
                    this.errors = response.data.errors;
                    this.isError = true;
                }
            },
            submitError(error) {
                this.errorHeader = 'error.general';
                this.errors = [{'field': null, 'message': 'error.generalMessage'}];
                this.isError = true;
            },
            validationError() {
                this.errorHeader = 'error.invalidFields';
                this.errors = this.getErrors();
                this.isError = true;
            },
            isErrorField(field) {
                try {
                    if (this.getValidationField(field).$error) {
                        return true;
                    }
                } catch (error) {}

                return this.errors.some(el => el.field === field);
            },
            getErrors() {
                let errors = [];
                for (const field of Object.keys(this.form)) {
                    try {
                        if (this.getValidationField(field).$error) {
                            errors.push({'field': field, 'message': null});
                        }
                    } catch (error) {}
                }
                return errors;
            },
            getFieldClasses(field) {
                return { 'is-invalid': this.isErrorField(field) }
            },
            getValidationField(field) {
                if (this.$v.form[field]) {
                    return this.$v.form[field];
                }
                throw Error('No validation for field ' + field);
            },
            onFieldBlur(field) {
                try {
                    this.getValidationField(field).$touch();
                    if (this.getValidationField(field).$error) {
                        if (!this.errors.some(el => el.field === field)) {
                            this.errors.push({'field': field, 'message': null});
                        }
                    } else {
                        this.errors = this.errors.filter(el => el.field !== field);
                    }
                } catch (error) {}
            },
            reload() {
                window.location = '/workforce-development/internships';
            }
        },
        validations() {
            return {
                form: {
                    email: { required, email },
                    firstName: { required },
                    lastName: { required },
                    requestedDepartment:  { required },
                    districtOfficeDesired:  { required },
                    resume: { required },
                    haveYouWorkedForMmrBefore: { required },
                    doYouHaveAnActiveTwicCard: { required },
                }
            }
        },
        watch: {
            errors() {
                this.isError = this.errors.length > 0 ? true : false;
            }
        }
    }
</script>
