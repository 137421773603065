<template>
    <div class="content-image">
        <div class="overflow-hidden">
            <div class="photo-carousel">
                <VOwlCarousel :items="1" :autoplay="true" :loop="true" :dots="false" :nav="false">
                    <template slot="prev">
                        <button class="owl-prev">
                        </button>
                    </template>

                    <template slot="next">
                        <button class="owl-next">
                        </button>
                    </template>

                    <template slot="default">
                        <slot></slot>
                    </template>
                </VOwlCarousel>
            </div>
        </div>
    </div>
</template>

<script>
    import VOwlCarousel from "v-owl-carousel";
    export default {
        name: "photoCarousel",
        data() {
            return {

            }
        },
        created: function () {
            // `this` points to the vm instance

        },
        components: {
            VOwlCarousel
        },
        methods: {

        }
    }
</script>

