<template>
    <div>
        <div class="row justify-center items-start">
            <button v-for="(listItem, index) in pullResults" class="thumblist-button" @click="projectUp(listItem.id)">
                <div class="thumblist-image">
                    <picture>
                        <source v-bind:srcset="listItem.featuredImage[0].thumbOpts.srcsetWebP"
                                sizes="(min-width: 768px) 33vw, 100vw"
                                type="image/webp" />
                        <img v-bind:src="listItem.featuredImage[0].thumbOpts.src"
                             v-bind:srcset="listItem.featuredImage[0].thumbOpts.srcset"
                             sizes="(min-width: 768px) 33vw, 100vw"
                        />
                    </picture>
                </div>
                <span class="thumblist-title-projects">{{ listItem.title }}</span>
                <span class="thumblist-location-projects">{{ listItem.projectLocation }}</span>
            </button>
        </div><!--row-->
        <div class="py-2" v-show="showSpinner">
            <div class="row">
                <div class="w-full text-center">
                    <img src="/assets/images/ajax-loader-gray-300.gif" alt="Loading" class="mx-auto">
                </div>
            </div>
        </div>
        <div class="my-6" v-show="hasNextPage">
            <div class="row">
                <div class="w-full column text-center text-lg">
                    <button class="btn-hex" v-on:click="loadMorePosts()">Load More Projects
                        <svg class="right-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,0l0,78.76l20.01,-39.357l-20.01,-39.403Z" ></path></svg>
                        <svg class="left-edge" viewBox="0 0 21 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M20.01,0l0,78.76l-20.01,-39.357l20.01,-39.403Z"></path></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    const limit = 6;
    const apiToken = 'gG0bSOrwD6Zhu0tqkbIb8QUgf3GPHUhnK518hNzjcVXfE7Fwy1oLGTA0zMSDhuzT';
    const apiUrl = '/api';

    const pullQuery =
        `
        query fetchProjects($relatedIndustry: [RelatedToInputType], $limit: Int, $offset: Int) {
              entriesConnection(relatedTo:$relatedIndustry, section:[projects], limit:$limit, offset:$offset, orderBy: "title asc") {
                totalCount
                pageInfo {
                  currentPage
                  totalPages
                  hasNextPage
                }
                edges {
                  node {
                    ...on Projects {
                      id
                      title
                      projectLocation
                      featuredImage {
                          ...on BannersVolume {
                              title
                              thumbOpts {
                                  ...on OptimizedImagesData {
                                      src,
                                      srcset,
                                      srcWebp,
                                      srcsetWebp
                                  }
                              }
                          }
                      }
                    }
                  }
                }
              }
            }
        `
    ;

    const configureApi = (url, token) => {
        return {
            baseURL: url,
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        };
    };

    const executeQuery = (api, query, variables, callback) => {
        api.post('', {
            query: query,
            variables: variables
        }).then((result) => {
            if (callback) {
                callback(result.data);
            }
            console.log(result.data);
        }).catch((error) => {
            console.log(error);
        })
    };

    var vueRoot = null;

    const getDetails = (prjId) => {
        var projectUrl = '/projects/details/' + prjId;
        return axios.get(projectUrl);
    }

    export default {
        name: "projectList",
        data: () => ({
            currentPageNum: 0,
            pullResults: [],
            showSpinner: 1,
            searchApi: null,
            hasNextPage: false,
            totalItems: 0,
            resultData: null,
            loadButtonText: '',
        }),
        props: {
            industry: {
                type: String
            }
        },
        methods: {
            loadMorePosts: function() {
                this.showSpinner = 1;
                const currentOffset = this.currentPageNum * limit;
                const variables = {
                    relatedIndustry: [{element: parseInt(this.industry)}],
                    limit: limit,
                    offset: currentOffset
                };
                executeQuery(this.searchApi, pullQuery, variables, (data) => {
                    console.log(data);
                    console.log('variables', variables);
                    console.log('api', this.searchApi);

                    this.resultData = data;
                    this.totalItems = this.resultData.data.entriesConnection.totalCount;
                    for (var nodeItem of this.resultData.data.entriesConnection.edges) {
                        this.pullResults.push(nodeItem.node);
                    }
                    this.currentPageNum++;
                    this.hasNextPage = this.resultData.data.entriesConnection.pageInfo.hasNextPage;
                    this.showSpinner = 0;
                });
            },
            projectUp: function(itemId) {
                vueRoot.modalContent = '<div class="loadspinner-locations"></div>';
                vueRoot.modalActive = true;
                getDetails(itemId).then(function (response) {
                    vueRoot.modalContent = response.data;
                }).catch(function (response) {
                    console.log(response);
                });
            },
        },
        mounted () {
            this.searchApi = axios.create(configureApi(apiUrl, apiToken));
            this.loadMorePosts();
        },
        created() {
            vueRoot = this.$parent;
        }

    }
</script>

